// extracted by mini-css-extract-plugin
export var article = "about-module--article--26mAj";
export var section = "about-module--section--1drwa";
export var sectionContainer = "about-module--section-container--1Vv4N";
export var sectionLeft = "about-module--section-left--3hT5r";
export var sectionRight = "about-module--section-right--3eezV";
export var sectionHeader = "about-module--section-header--2okzU";
export var sectionImg = "about-module--section-img--fp7XQ";
export var memberContainer = "about-module--member-container--20WhW";
export var memberLeft = "about-module--member-left--w9YVO";
export var memberImg = "about-module--member-img--2ndYa";
export var memberRight = "about-module--member-right--3sFcp";
export var memberName = "about-module--member-name--2lqXx";